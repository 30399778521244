@media (max-width: 540px) {

    .slick-prev:before,
    .slick-next:before {
        font-size: 40px;
    }

    .padLeft32,
    .padLeft48 {
        padding-left: 0 !important;
    }
    
    .pad0540 {
        padding: 0;
    }
    
     p {
        font-size: 14px;
        text-align: center;
    }
    h2,
     h3 {
        font-size: 18px;
        text-align: center;
    }
    h4 {
        font-size: 16px;
        text-align: center;
    }
    ul {
        padding-left: 0;
        text-align: center;
        list-style-type: none;
        li {
            font-size: 14px;
            ul {
                list-style-type: none;
            }
        }
    }
    .detailsUl {
        list-style-type: none;
        li {
            list-style-type: none;
        }
    }
    
    .foodImg {
        section {
            img {
            width: 100%;
            }
        }
    }
    
    
    .listDisc {
        list-style-type: none;
    }
    
    .foodUl,
    .barnUl {
        list-style-type: none;
        > li {
            padding-top: 15px;
        }
        li {
            span {
                font-size: 16px;
            }
        }
    }
    
    .mainNavigation {
        a {
            text-align: center;
            display: block;
            margin: 0 auto;
        }
        ul {
            float: none;
            display: block;
            margin: 0;
            li {
                display: block;
            }
        }
    }
    
    .neighbourhoodImg {
        float: none;
        margin: 0 auto;
        img {
            min-height: auto;
        }
    }
    
    .bigGallery {
        .indexImg {
            img {
                height: 140px;
            }
        }
    } 
    
    .galleryIndex,
    .galleryMedia {
        img {
            height: 250px;
            width: 100%;
        }
    }
    
    .alignLeft {
        text-align: center;
    }
    
    .paddingL63px {
        padding-left: 0;
    }
    
    .details {
        &:after {
            top: 14px;
        }
    }
    
    .kids {
        section {
            &:nth-child(2) {
                padding: 0;
            }
        }
    }
    
    .pad0540 {
        padding: 0;
    }
    
    .paddingL20px {
        padding-left: 0px;
    }
    
    .kidsGallery {
        .indexImg {
            img {
                height: auto;
            }
        }
    }
    
    .galleryIndex {
            p {
                bottom: 62px;
            }
        }
    .width640 {
        width: 100%;
    }
    
    .maxWidthContent {
        section {
            width: 95%;
        }
    }
    
    .font22px {
        font-size: 16px;
    }
    
    .youtube {
        height: 200px;
        margin-bottom: 50px;
    }
    
    .reservationText {
        position: static;
    }
    
    .flexDisplay {
        display: flex;
        flex-direction: column-reverse;
    }  
    
    .marginTop50px {
        margin-top: 0;
    }
    
    .container1000 {
        margin: 0 auto;
        .h2room {
            position: static;
            font-size: 26px;
        }
        .reservationGallery {
            &:last-child {
                height: auto;
            }
            p {
                padding: 0 5px 0 5px;
            }
            .row {
                section {
                    img {
                        width: 100%;
                    }
                }
                div {
                    text-align: left;
                    margin-bottom: 50px;
                    h2 {
                        position: static;
                        padding-left: 20px;
                        margin: 5px 0 10px 0;
                        text-align: left;
                        font-size: 22px;
                    }

                    .pricePosition {
                        position: static;
                        padding-left: 20px;
                        margin-bottom: 10px;
                        text-align: left;
                        font-size: 22px;
                        span {
                            font-size: 18px
                        }
                    }
                    .roomPosition {
                        position: static;
                        padding-left: 20px;
                        margin-bottom: 5px;
                        text-align: left;
                    }
                    .buttonReservation {
                        display: block;
                        margin-left: 20px;
                        position: static;
                    }
                }
            }
        }
    } 
    
    .galleryNeighbourhood {
        p {
            height: 40px;
            bottom: auto;
            opacity: 1;
        }
    }
    
    
    .arrowImage {
        display: block;
        position: absolute;
        width: 130px;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
    
}
    
    
@media (min-width: 474px) and (max-width: 540px) {
    .container1000 {
        .reservationGallery {
            .row {
                div {
                    padding-left: 39px;
                }
            }
        }
    }
}

@media (min-width: 540px) and (max-width: 991px) {

    .arrowImage {
        display: none;
    }    
    
    
    
        p {
            font-size: 16px;
            text-align: center;
        }
        h2,
        h3 {
            font-size: 20px;
            text-align: center;
        }
    
        h4 {
            font-size: 18px;
            text-align: center;
        }
    
        ul {
            font-size: 16px;
        }
    
    .detailsUl {
        list-style-type: none;
        li {
            list-style-type: none;
        }
    }
    
    .foodImg {
        section {
            img {
            width: 100%;
            }
        }
    }
    
    
    .container {
        .roomname {
            h2 {
                text-align: center;
            }
            p {
                text-align: center;
            }
            ul {
                padding-left: 0;
                text-align: center;
                li {
                    font-size: 16px;
                }
            }
        }
        
    }
    
    .bigGallery {
        .indexImg {
            img {
                height: 170px;
            }
        }
    } 
    
    .galleryIndex,
    .galleryMedia {
        img {
            height: 230px;
            width: 100%;
        }
    }
    
    .galleryIndex {
            p {
                bottom: 61px;
            }
        }
    
    
    .barnUl {
        list-style-type: none;
        text-align: left;
        li {
            ul {
                list-style-type: disc;
            }
        }
    }
    
    .galleryMedia {
        p {
            height: 40px;
            bottom: auto;
            opacity: 1;
        }
    }
    
    .font22px {
        font-size: 18px;
    }
    
    .paddingL45px {
        padding-left: 0;
    }
    
    .alignLeft {
        text-align: left;
    }
    
    .alignLeftImp {
        text-align: left;
    }
    
    .fontBold {
        font-weight: bold;
    }
    
    .marginTop50px {
        margin-top: 50px;
    }
    
    .width640 {
        width: 70%;
    }
    
    .maxWidthContent {
        section {
            width: 80%;
        }
    }
    
    .container1000 {
        margin: 0 auto;
        .h2room {
            position: static;
        }
        .reservationGallery {
            .row {
                section {
                    img {
                        width: 100%;
                    }
                }
                div {
                    text-align: left;
                    margin-bottom: 50px;
                    h2 {
                        position: static;
                        padding-left: 20px;
                        text-align: left;
                        font-size: 26px
                    }
                    .pricePosition {
                        position: static;
                        padding-left: 20px;
                        text-align: left;
                        margin-bottom: 12px;
                        font-size: 26px;
                        span {
                            font-size: 18px
                        }
                    }
                    .roomPosition {
                        position: static;
                        padding-left: 20px;
                        text-align: left;
                        margin-bottom: 12px;
                    }
                    .buttonReservation {
                        display: block;
                        margin-left: 20px;
                        position: static;
                        }
                    }
                }
            }
    }
    
}

@media (min-width: 541px) and (max-width: 700px) {
    .reservationGallery {
        .row {
            section {
                img {
                    width: 100%;
                }
            }
        }
    }
}



@media (min-width: 992px) {
     
    
    .foodImg {
        section {
            img {
            width: 100%;
            }
        }
    }
    
    
    .container {
        .mini-container,
        .reservationGallery {
            margin-top: 50px;
             p {
                font-size: 16px;
            }
            h2 {
                font-size: 30px;
            }
            .row {
                ul {
                    font-size: 16px;
                }
            }
        }
    }
    
    .bigGallery {
        .indexImg {
            img {
                height: 170px;
            }
        }
    }
    
    .galleryIndex {
        img {
            height: 259px;
            width: 100%;
        }
    }
    
    .foodUl {
        list-style-type: none;
        text-align: left;
        li {
            ul {
                list-style-type: disc;
            }
        }
    }
    
    .barnUl {
        list-style-type: none;
        text-align: left;
        li {
            ul {
                list-style-type: disc;
            }
        }
    }
    
    .font22px {
        font-size: 22px;
    }
    
    .marginTop50px {
        margin-top: 50px;
    }
    
    .alignCenter {
        text-align: center;
    }    

    .alignLeft {
        text-align: left;
    }

    .fontBold {
        font-weight: bold;
    }

    .font12px {
        font-size: 12px;
    }

    .font18px {
        font-size: 18px;
    }

    .padding5px {
        padding: 5px;
    }

    .paddingL45px {
        padding-left: 45px;
    }

    .paddingL63px {
        padding-left: 63px;
    }
    
    .alignLeftImp {
        text-align: left;
    }
    
    
}


@media (max-width: 1040px) {
    .mainHeader {
        .address {
            width: 30%;
            margin-right: 5%;
        }
        .logotype {
            width: 30%;
            margin-right: 5%;
        }
        .contactUs {
            width: 30%;
        }
    }
    .mainPage {
        max-width: 700px;
        .marginTop40 {
            margin-top: 20px;
        }
        .marginRight40 {
            margin-right: 20px;
        }
        section {
            min-width: 300px;
            margin: 20px;
        }
        .width640 {
            min-width: 640px;
        }
        .height640 {
            height: 300px;
        }
        .mobileWidth640 {
            min-width: 640px
        }
    }
    .foodContent {
        section:first-of-type {
            padding-left: 40px;
        }
        section:last-of-type {
            padding-right: 40px;
        }
        section h3 {
            margin: 20px 0;
        }
    }
}

@media (max-width: 695px) {
    .mainHeader {
        padding: 40px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
            text-align: center;
            min-height: 0;
        }
        .address {
            order: 1;
            width: 100%;
            margin-right: 0;
            padding-top: 20px;
        }
        .logotype {
            width: 100%;
            margin-right: 0;
            min-height: 180px;
            padding-top: 20px;
            min-width: 200px;
        }
        .contactUs {
            height: auto;
            padding-top: 0;
            order: 3;
            width: 100%;
            padding-top: 20px;
        }
    }
    .sectionHeader {
        margin: 0 auto 20px auto;
    }
    .mainContent {
        max-width: 100%;
        padding: 20px 5% 0px 5%;
        .marginTop40 {
            margin-top: 20px;
        }
        .marginRight40 {
            margin-right: auto;
        }
        section {
            min-width: 100%;
            margin: 10px auto 0 auto;
        }
        .width640 {
            width: 100%;
            min-width: 100%;
        }
        .mobileWidth640 {
            width: 100%;
            min-width: 100%;
        }
        #instagram {
            height: auto;
            background: none;
            .imgDiv {
                background: none;
            }
        }
    }
    input:checked + .slide-container .nav label {
        display: none;
    }
    .mainSlides .slide {
        background-attachment: scroll;
    }
    .foodContent {
        section:first-of-type {
            padding-left: 0;
        }
        section:last-of-type {
            padding-right: 0;
        }
    }
}

@media (max-width: 330px) {
    .reservationGallery {
        .row {
            section {
                img {
                    width: 100%;
                }
            }
        }
    }
}