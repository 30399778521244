.reservationWrapper {
    font-family: 'Open Sans', sans-serif;
    width: 100vw;
    height: 60px;
    position: fixed;
    top: 0;
    display: none;
    z-index: 600;
    background: rgba(0, 0, 0, 0.7);
    .reservation{
        max-width: 1000px;
        margin: 10px auto 10px auto;
        position: relative;
        img {
            padding: 0 10px 0 5px;
        }
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: inline-block;
            li {
                display: inline-block;
                height: 40px;
                width: 140px;
                font-size: 14px;
                line-height: 40px;
                border-radius: 2px;
                outline: none;
                border: 1px solid #ccc;
                background: #fff;
                color: #000;
                box-sizing: border-box;
                padding-left: 14px;
                margin-right: 5px;
                position: relative;
                top: 1px;
                span {
                    line-height: 40px;
                    font-size: 14px;
                }
            }
        }
        #datepicker {
            margin-left: 60px;
        }
         div > input {
            height: 40px;
            width: 140px;
            font-size: 14px;
            border-radius: 2px;
            outline: none;
            border: 1px solid #ccc;
            background: #fff;
            color: #000;
            box-sizing: border-box;
            padding-left: 14px;
            margin-right: 5px;
            /*&:focus {
                border: 1px solid #fff;
            }*/
        }
        button {
            width: 140px;
            height: 40px;
            background: #000;
            color: #fff;
            background: #567ad3;
            outline: none;
            border: none;
            cursor: pointer;
            font-size: 14px;
            text-transform: uppercase;
            position: relative;
            top: -1px;
            &:hover{
                background: #789cf5;
            }
        }
        .kids2,
        .adult2 {
            text-align: center;
            display: none;
            width: 160px;
            height: 88px;
            background: #333;
            color: #fff;
            user-select: none;
            font-size: 16px;
            border: 1px solid #666;

            span {
                position: relative;
                top: 8px;
                font-size: 14px;
                font-weight: 600;
            }

            p {
                display: inline-block;
                text-align: center;
                margin: 0;
                font-size: 20px;
                cursor: pointer;
                background: #fff;
                border-radius: 50%;
                width: 30px;
                line-height: 30px;
                height: 30px;
                position: relative;
                top: 16px;
                color: #000;
                
            }
            .minus {
                line-height: 26px;
                top: 14px;
            }
            .smallInput {
                width: 43px;
                height: 29px;
                background: #333;
                color: #fff;
                position: relative;
                top: 17px;
                left: 2px;
                box-sizing: border-box;
                border: none;
                font-size: 28px;
                text-align: center;
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                padding: 0;
            }
        }
        /*.kids2 {
            position: absolute;
            left: 467px;
        }
        .adult2 {
            position: absolute;
            left: 311px;
        }*/
    }
    .closeReservationWrapper {
        cursor: pointer;
        float: right;
        margin-right:50px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        img {
            position: absolute;
            top: 0;
        }
    }
}

::-webkit-input-placeholder {
  color: #000;
}
::-moz-placeholder { 
  color: #000;
}
:-ms-input-placeholder { 
  color: #000;
}
:-moz-placeholder { 
  color: #000;
}
