//Fixed background slider version

    
.heroImgIndex  {background: url("../images/room/calosc/6.jpg") ;}
.heroImgRoom {background: url("../images/room/main/pokoje.jpg");}
//.heroImgAbout {background: url("../images/neighbourhood/2.jpg");}
.heroImgAbout {background: url("../images/room/main/about.jpg");}
.heroImgBarn {background: url("../images/room/main/stodola.jpg");}
.heroImgNeighbourhood {background: url("../images/neighbourhood/10.jpg");}
.heroImgLocation {background: url("../images/room/main/lokalizacja.jpg");}
.heroImgPrices {background: url("../images/about/5.jpg");}
.heroImgKids {background: url("../images/room/main/dzieci.jpg");}
.heroImgFood {background: url("../images/food/4.jpg");}
.heroImgMedia {background: url("../images/room/main/1.jpg");}

.shadowImg {
    background: url("../images/shadow2.png");
    background-size: cover;
    background-size: 100% 232px;
};

.mainSlide1 {background: url("../images/about/2.jpg");}
.mainSlide2 {background: url("../images/about/3.jpg");}
.mainSlide3 {background: url("../images/about/4.jpg");}


.foodSlide1 {background: url("../images/food/1.jpg");}
.foodSlide2 {background: url("../images/food/2.jpg");}
.foodSlide3 {background: url("../images/food/3.jpg");}
.foodSlide4 {background: url("../images/food/4.jpg");}
.foodSlide5 {background: url("../images/food/5.jpg");}
.foodSlide6 {background: url("../images/food/6.jpg");}
.foodSlide7 {background: url("../images/food/7.jpg");}
.foodSlide8 {background: url("../images/food/8.jpg");}
.foodSlide9 {background: url("../images/food/9.jpg");}
.foodSlide10 {background: url("../images/food/10.jpg");}



.roomSlide1 {background: url("../images/room/konikgarbusek/1.jpg");}
.roomSlide2 {background: url("../images/room/konikgarbusek/2.jpg");}
.roomSlide3 {background: url("../images/room/damazpieskiem/1.jpg");}
.roomSlide4 {background: url("../images/room/damazpieskiem/2.jpg");}
.roomSlide5 {background: url("../images/room/taktucicho/1.jpg");}
.roomSlide6 {background: url("../images/room/taktucicho/2.jpg");}
.roomSlide7 {background: url("../images/room/swiatsiesmieje/1.jpg");}
.roomSlide8 {background: url("../images/room/swiatsiesmieje/4.jpg");}
.roomSlide9 {background: url("../images/room/malymarzyciel/1.jpg");}
.roomSlide10 {background: url("../images/room/malymarzyciel/4.jpg");}


.barnSlide1 {background: url("../images/room/stodola/1.jpg");}
.barnSlide2 {background: url("../images/room/stodola/2.jpg");}
.barnSlide3 {background: url("../images/room/stodola/3.jpg");}



.kidsSlide1 {background: url("../images/dzieci/1.jpg");}
.kidsSlide2 {background: url("../images/dzieci/2.jpg");}
.kidsSlide3 {background: url("../images/dzieci/3.jpg");}
.kidsSlide4 {background: url("../images/dzieci/4.jpg");}
.kidsSlide5 {background: url("../images/dzieci/5.jpg");}
.kidsSlide6 {background: url("../images/dzieci/6.jpg");}
.kidsSlide7 {background: url("../images/dzieci/7.jpg");}
.kidsSlide8 {background: url("../images/dzieci/8.jpg");}
.kidsSlide9 {background: url("../images/dzieci/9.jpg");}


.neighbourhoodImg1 {background: url("../images/neighbourhood/1.jpg");}
.neighbourhoodImg2 {background: url("../images/neighbourhood/2.jpg");}
.neightbourhoodImg3 {background: url("../images/neighbourhood/3.jpg");}



.aboutImg {background: url("../images/about/1.jpg");}
.roomImg1 {background: url(../images/room/konikgarbusek/1.jpg) no-repeat center;}
.roomImg2 {background: url(../images/room/damazpieskiem/1.jpg) no-repeat center;}
.roomImg3 {background: url(../images/room/taktucicho/1.jpg) no-repeat center;}
.roomImg4 {background: url(../images/room/swiatsiesmieje/1.jpg) no-repeat center;}
.roomImg5 {background: url(../images/room/malymarzyciel/1.jpg) no-repeat center;}



.konikGarbusek1 {background: url("../images/room/konikgarbusek/1.jpg");}
.konikGarbusek2 {background: url("../images/room/konikgarbusek/2.jpg");}
.konikGarbusek3 {background: url("../images/room/konikgarbusek/3.jpg");}
.konikGarbusek4 {background: url("../images/room/konikgarbusek/4.jpg");}
.konikGarbusek5 {background: url("../images/room/konikgarbusek/5.jpg");}
.konikGarbusek6 {background: url("../images/room/konikgarbusek/6.jpg");}


.damazPieskiem1 {background: url("../images/room/damazpieskiem/1.jpg");}
.damazPieskiem2 {background: url("../images/room/damazpieskiem/2.jpg");}
.damazPieskiem3 {background: url("../images/room/damazpieskiem/3.jpg");}
.damazPieskiem4 {background: url("../images/room/damazpieskiem/4.jpg");}
.damazPieskiem5 {background: url("../images/room/damazpieskiem/5.jpg");}
.damazPieskiem6 {background: url("../images/room/damazpieskiem/6.jpg");}
.damazPieskiem7 {background: url("../images/room/damazpieskiem/7.jpg");}
.damazPieskiem8 {background: url("../images/room/damazpieskiem/8.jpg");}


.malymarzyciel1 {background: url("../images/room/malymarzyciel/1.jpg");}
.malymarzyciel2 {background: url("../images/room/malymarzyciel/2.jpg");}
.malymarzyciel3 {background: url("../images/room/malymarzyciel/3.jpg");}
.malymarzyciel4 {background: url("../images/room/malymarzyciel/4.jpg");}
.malymarzyciel5 {background: url("../images/room/malymarzyciel/5.jpg");}
.malymarzyciel6 {background: url("../images/room/malymarzyciel/6.jpg");}
.malymarzyciel7 {background: url("../images/room/malymarzyciel/7.jpg");}
.malymarzyciel8 {background: url("../images/room/malymarzyciel/8.jpg");}


.swiatsiesmieje1 {background: url("../images/room/swiatsiesmieje/1.jpg");}
.swiatsiesmieje2 {background: url("../images/room/swiatsiesmieje/2.jpg");}
.swiatsiesmieje3 {background: url("../images/room/swiatsiesmieje/3.jpg");}
.swiatsiesmieje4 {background: url("../images/room/swiatsiesmieje/4.jpg");}
.swiatsiesmieje5 {background: url("../images/room/swiatsiesmieje/5.jpg");}
.swiatsiesmieje6 {background: url("../images/room/swiatsiesmieje/6.jpg");}
.swiatsiesmieje7 {background: url("../images/room/swiatsiesmieje/7.jpg");}


.taktucicho1 {background: url("../images/room/taktucicho/1.jpg");}
.taktucicho2 {background: url("../images/room/taktucicho/2.jpg");}
.taktucicho3 {background: url("../images/room/taktucicho/3.jpg");}
.taktucicho4 {background: url("../images/room/taktucicho/4.jpg");}
.taktucicho5 {background: url("../images/room/taktucicho/5.jpg");}
.taktucicho6 {background: url("../images/room/taktucicho/6.jpg");}
.taktucicho7 {background: url("../images/room/taktucicho/7.jpg");}


//media 

.media1 {background: url("../images/artykuly/linki/mrspolka.jpg");}
.media2 {background: url("../images/artykuly/linki/tastyplaces.jpg");}
.media3 {background: url("../images/artykuly/linki/dookolanas.jpg");}
.media4 {background: url("../images/artykuly/linki/fathers.jpg");}
.media5 {background: url("../images/artykuly/linki/natemat.jpg");}
.media6 {background: url("../images/artykuly/linki/ladnebebe.jpg");}
.media7 {background: url("../images/artykuly/linki/fpiec.jpg");}
.media8 {background: url("../images/artykuly/linki/slowroad.jpg");}
.media9 {background: url("../images/artykuly/linki/turystykawp.jpg");}
.media10 {background: url("../images/artykuly/linki/travelic.jpg");}


//index

.index1 {background: url("../images/room/konikgarbusek/4.jpg");}
.index2 {background: url("../images/food/4.jpg");}
.index3 {background: url("../images/dzieci/5.jpg");}
.index4 {background: url("../images/about/1.jpg");}
.index5 {background: url("../images/room/stodola/1.jpg");}
.index6 {background: url("../images/neighbourhood/3.jpg");}
.index7 {background: url("../images/about/5.jpg");}
.index8 {background: url("../images/neighbourhood/15.jpg");}
.index9 {background: url("../images/room/main/1.jpg");}

//neighbourhood

.neighbourhood1 {background: url("../images/neighbourhood/male/jdrawskie.jpg");}
.neighbourhood2 {background: url("../images/neighbourhood/male/staredrawsko.jpg");}
.neighbourhood3 {background: url("../images/neighbourhood/male/grod.jpg");}
.neighbourhood4 {background: url("../images/neighbourhood/male/czaplinek.jpg");}
.neighbourhood5 {background: url("../images/neighbourhood/male/siemczyno.jpg");}
.neighbourhood6 {background: url("../images/neighbourhood/male/kajaki.jpg");}
.neighbourhood7 {background: url("../images/neighbourhood/male/rowery.jpg");}
.neighbourhood8 {background: url("../images/neighbourhood/male/wiezawidokowa.jpg");}
.neighbourhood9 {background: url("../images/neighbourhood/male/kluczewo.jpg");}
.neighbourhood10 {background: url("../images/neighbourhood/male/polczynzdroj.jpg");}