@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import "globals";
@import (less) "lib/normalize.css";
@import "lib/base";
@import "lib/functions";

@import "widgets/font-awesome";
@import "widgets/cookie";
@import "widgets/columns";
@import "widgets/pikaday";
@import "widgets/slick";
@import "widgets/slick-theme";

@import "lib/dimensions";
@import "lib/typo";
@import "lib/helper";

@import "backgrounds";
@import "pages/reservation";

body {
    padding: 0;
    font-family: @tahoma;
    font-size: @baseFontSize + 0px;
    color: @blackAlmost;
    background-color: @white;
    background-repeat: no-repeat;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
}

a {
    color: @blue;
    text-decoration: none;
    .transition(all, .4s, cubic-bezier(0, 0, 0.25, 1));
    &:hover {
        color: @black;
        text-decoration: none;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1em;
}

h1, h2 {
    line-height: 1;
}

h1 {
    font-size: 32/@baseFontSize + 0em;
}

h2 {
    font-size: 32/@baseFontSize + 0em;
}

h3 {
    font-size: 27/@baseFontSize + 0em;
}

h4 {
    font-size: 24/@baseFontSize + 0em;
    font-weight: normal;
}

ul {
    margin: 1.25em 0;
}

p {
    margin-top: 0;
    margin-bottom: 1.25em;
}

img.fullWidth {
    max-width: 100%;
    display: block;
    height: auto;
}

//General settings

.wrapper {
    max-width: @pageWidth;
    margin: 0 auto;
    background-color: @wrapperBgColor;
    padding: 0 10px 0 10px;
}

//Header


.languages {
    list-style-type: none;
    display: inline-block;
    margin: 0 80px 0 0;
    padding: 0;
    overflow: hidden;
    float: right;
    li {
        display: inline-block;
        a {
            display: block;
            text-align: center;
            text-decoration: none;
            padding: 20px 10px;
            &.active {
                border-bottom: 3px solid #2f4590;
            }
        }
    }
}

.mainHeader {
    padding: 40px 80px;
    font-size: 0;
    div {
        font-size: 16px;
        display: inline-block;
        min-height: 200px;
        vertical-align: top;
        p {
            font-size: 14px;
            margin-bottom: 4px;
        }
        h2 {
            font-size: 18px;
        }
    }
    
    .logotype {
        width: 40%;
        margin-right: 10%;
        padding: 0 20px;
        background: url("../images/logo.png") no-repeat center;
        background-size: contain;
    }
    
}

.foodRelative {
    position: relative;
    top: -60px;
}

@media (min-width: 697px) {
    .mainHeader {
        .address {
        width: 25%;
        margin-right: 5%;
        padding-top: 30px;
        }
        .contactUs {
        width: 20%;
        padding-top: 30px;
        }
    }
}

@media (max-width: 696px) {
    .mainHeader {
        padding: 40px 20px 0px 20px;
        .address {
            width: 100%;
            margin-right: 0;
            padding-top: 0;
        }
        .contactUs {
            height: auto;
            padding-top: 0;
            order: 3;
            width: 100%;
            padding-top: 20px;
        }
    }
    
}

//Slider
.mainSlides {
    height: 50vh;
    min-height: 250px;
}

.foodSlides {
    height: 40vh;
    min-height: 180px;
}

.slides {
    padding: 0;
    width: 100%;
    display: block;
    margin: 0 auto;
    position: relative;
    * {
        user-select: none;
    }
    input {
        display: none;
    }
    .slide-container {
        display: block;
        .slide {
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            box-shadow: inset 0 7px 15px -7px rgba(0, 0, 0, 0.9);
            //Scale to hide unchecked the div
            transform: scale(0);
            // Delay transform to hide scaling
            transition: opacity .4s ease-in-out, transform 2s 0.1s ease-in;
        }
        .nav {
            label {
                width: 40px;
                height: 40px;
                display: none;
                position: absolute;
                top: ~"calc(50% - 20px)";
                margin-left: 40px;
                margin-right: 40px;
                z-index: 2;
                cursor: pointer;
                color: #ffffff;
                background-color: rgba(0, 0, 0, 0.7);
                border-radius: 50%;
                font-size: 40px;
                text-align: center;
                line-height: 0.8;
            }
            .next {
                right: 0;
            }
        }
    }
}

.slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainSlides .slide {
  background-attachment: fixed;
}

input:checked + .slide-container .slide {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.4s ease-in-out;
}

input:checked + .slide-container .nav label {
  display: block;
}


.height640 {
    height: 640px;
}

.height400 {
    height: 40.8%;
}

.width640 {
    width: 60%;
    margin: 0 auto;
}

.width70 {
    width: 70%;
    margin: 0 auto;
    margin: 0 auto;
}

.marginRight40 {
    margin-right: 4.1%;
}

.marginTop40 {
    margin-top: 40px;
}

.marginTop30px {
    margin-top: 30px;
}

.margin0 {
    margin: 0;
}

.marginTB30px {
    margin-top: 30px;
    margin-bottom: 30px;
}

.container30px {
    max-width: 1200px;
    margin: 30px auto 0 auto;
}

.padding0 {
    padding: 0;
}

.paddingL20px {
    padding-left: 20px;
}

.floatNoneMarginAuto{
    float: none;
    margin: 0 auto;
}

.footerContainer {
    margin: 0 auto;
    max-width: 1200px;
}


.shadowImg {
    height: 232px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}


// Indexhtml gallery

.galleryIndex {
    position: relative;
    p {
        opacity: 0;
        width: calc(~"100% - 10px");
        font-size: 20px;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 62px;
        top: 5px;
        background: rgba(0, 0, 0, 0.7);
        transition: all 0.5s ease-in;
        margin-bottom: 0;
        img {
            width: 30%;
            height: 30%;
        }
    }
    span {
        position: relative;
        display: block;
        top: 50%;
        transform: translateY(-50%);
    }
    &:hover p {
        opacity: 1;
    }
    h4 {
        font-size: 20px;
    }
}

.indexImg {
    position: relative;
    overflow: hidden;
    iframe,
    img {
        max-width: 100%;
        height: auto;
    }
    p {
        width: calc(~"100% - 10px");
        background: rgba(0, 0, 0, 0.7);
        margin: 0;
        padding: 10px 0 10px 0;
        opacity: 1;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s ease-in;
    }
    span {
        opacity: 0;
        width: calc(~"100% - 10px");
        font-size: 20px;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: -30px;
        left: 50%;
        background: rgba(0, 0, 0, 0.7);
        transform: translateX(-50%) translateY(-50%);
        transition: all 0.5s ease-in;
    }
    .img-hover {
        background: #2f4590;
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        transition: all 0.5s ease-in;
    }
    &:hover .img-hover {
        opacity: 0.6;
    }
    &:hover span {
        opacity: 1;
        bottom: -3px;
    }
}

.bigGallery {
    h2 {
        text-align: center;
    }
    h4 {
        color: #000;
        font-family: 'Open Sans',sans-serif;
        margin-top: 15px;
    }
    .indexImg {
        transition: all 0.2s ease-in;
        opacity: 1;
        &:hover {
            opacity: 0.8;
        }
        img {
            width: 100%;
        }
    }
}

//media gallery


.media1,
.media2,
.media3,
.media4,
.media5,
.media6,
.media7,
.media8,
.media9,
.media10,
.index1,
.index2,
.index3,
.index4,
.index5,
.index6,
.index7,
.index8,
.index9 {
    background-size: cover;
    position: relative;
    box-shadow: 10px 10px 25px -4px rgba(0,0,0,0.32);
    margin: 5px;
    transition: all 0.5s ease-in;
    &:hover {
        box-shadow: 0px 20px 24px 0px rgba(0,0,0,0.32);
        transform: scale(1.11);
        z-index: 40;
    }
    &:hover p {
        opacity: 1;
    }
    &:hover h4 {
        background: rgba(0,0,0,0);
    }
    h4 {
        background: rgba(0, 0, 0, 0.7);
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        color: #fff;
        transition: all 0.5s ease-in;
    }
    h3 {
        font-size: 30px;
    }
    img {
        width: 20%;
        height: 20%;
    }
    p {
        opacity: 0;
        width: 100%;
        font-size: 20px;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 0px;
        top: 0px;
        right: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.7);
        transition: all 0.5s ease-in;
        margin-bottom: 0;
        span {
            margin: 0 auto;
            position: relative;
            display: block;
            padding: 40px 33px;
            height: auto;
            text-align: center;
            span { 
                font-size: 16px;
                text-transform: uppercase;
                padding: 0;
                
            }
        }
    }
}

.index1,
.index2,
.index3,
.index4,
.index5,
.index6,
.index7,
.index8,
.index9 {
    height: 250px;
    background-position: center;
    p {
        background: rgba(0,0,0,0);
    }
}

.media1,
.media2,
.media3,
.media4,
.media5,
.media6,
.media7,
.media8,
.media9,
.media10 {
    height: 200px;
}



.neighbourhood1,
.neighbourhood2,
.neighbourhood3,
.neighbourhood4,
.neighbourhood5,
.neighbourhood6,
.neighbourhood7,
.neighbourhood8,
.neighbourhood10,
.neighbourhood9 {
    background-size: cover;
    position: relative;
    height: 200px;
    box-shadow: 10px 10px 25px -4px rgba(0,0,0,0.32);
    margin: 5px;
    transition: all 0.5s ease-in;
    &:hover {
        box-shadow: 16px 16px 24px 5px rgba(0,0,0,0.32);
        transform: scale(1.1);
        z-index: 40;
    }
    &:hover p {
        opacity: 1;
    }
    p {
        opacity: 0;
        width: 100%;
        font-size: 20px;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 0px;
        top: 0px;
        right: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.7);
        transition: all 0.5s ease-in;
        margin-bottom: 0;
        span {
            margin: 0 auto;
            width: 90%;
            position: relative;
            display: block;
            top: 10%;
            padding: 10px 0 10px 0;
        }
    }
}

@media (min-width: 320px) and (max-width: 991px) {
    .neighbourhood1,
    .neighbourhood2,
    .neighbourhood3,
    .neighbourhood4,
    .neighbourhood5,
    .neighbourhood6,
    .neighbourhood7,
    .neighbourhood8,
    .neighbourhood10, 
    .neighbourhood9 {
        p {
            opacity: 1;
            bottom: initial;
            padding: 5px;
            span {
                position: static;
                transform: translateY(0);
                &:nth-child(2),
                &:nth-child(3) {
                    display: none;
                }
            }
            
        }
    }
    
}

.galleryMedia {
    position: relative;
    &:hover p {
        opacity: 1;
    }
    img {
        width: 100%;
    }
    p {
        opacity: 0;
        width: calc(~"100% - 10px");
        font-size: 20px;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 12px;
        background: rgba(0, 0, 0, 0.7);
        transition: all 0.5s ease-in;
        margin-bottom: 0;
        span {
            margin: 0 auto;
            width: 90%;
            position: relative;
            display: block;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


.galleryNeighbourhood {
    p {
        top: 5px;
    }
}

//Menu
.closeMenu {
    position: absolute;
    left: 10px;
    top: 10px;
    font-weight: bold;
    cursor: pointer;
    height: 40px;
}

.hiddenMenu {
    position: fixed;
    top: 0;
    left: -400px;
    height: 100%;
    background-color: @menuBackground;
    width: 280px;
    max-width: 100vw;
    margin: 0 auto;
    z-index: 1000;
    transition: all 0.3s ease-in;
    ul {
        border-top: 1px solid @menuColor;
        list-style-type: none;
        padding: 0;
        margin: 60px 0px 60px 20px;
    }
    li {
        border-bottom: 1px solid @menuColor;
        a {
            padding: 10px 10px 10px 0;
            display: block;
            color: @menuColorTx;
            &:hover {
                color: @white;
            }
        }
    }
}

.moveMenu {
    left: 0;
}

// Food page
.sectionHeader {
    margin: 0 auto 50px auto;
    text-align: center;
    h2 {
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 0;
        line-height: 1.5;
    }
}

.foodImg {
    width: 100%;
    min-height: 30vh;
    background-size: cover;
}

.foodContent {
    section {
        background: transparent;
        width: 47.95%;
        height: auto;
        .foodImg {
            width: 100%;
            min-height: 30vh;
            background-size: cover;
        }
        h3 {
            margin: 40px 0;
            text-align: center;
        }
        &:hover div {
            opacity: 1;
        }
        p {
            line-height: 1.2;
        }
    }
}

.maxWidthContent {
    .imgwrap {
        max-width: 600px;
        height: auto;
        display: block;
        margin: 0 auto;
        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto 50px auto;
            display: block;
        }
    }
}

.maxWidthContent {
    section {
        width: 80%;
        margin: 0 auto;
    }
}

.carouselWrapp {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    max-height: 660px;
    height: 100%;
    img {
        height: 660px;
    }
}

@media (min-width: 991px) {
    .arrowImage {
        display: none;
    }
}

@media (min-width: 991px) and (max-width: 1200px) {
    .carouselWrapp {
        img {
            height: 530px;
        }
    }
}

@media (min-width: 780px) and (max-width: 990px) {
    .carouselWrapp {
        img {
            height: 500px;
        }
    }
}

@media (min-width: 630px) and (max-width: 779px) {
    .carouselWrapp {
        img {
            height: 450px;
        }
    }
}


@media (min-width: 541px) and (max-width: 629px) {
    .carouselWrapp {
        img {
            height: 400px;
        }
    }
}

@media (min-width: 381px) and (max-width: 540px) {
    .carouselWrapp {
        img {
            height: 350px;
        }
    }
}

@media (max-width: 380px) {
    .carouselWrapp {
        img {
            height: 300px;
        }
    }
}

.container {
    max-width: 1200px;
    margin: 50px auto 0 auto;
    .mini-container {
        margin-top: 50px;
        line-height: 1.4;
        .row {
            .l-col-6 {
                img {
                    width: 100%;
                    height: 600px;
                }
            }
            .m-col-2,
            .s-col-4 {
                img {
                    width: 100%;
                    height: auto;
                }
            }
            ul {
                margin: 0;
                display: none;
            }
        }
    }
}

.alignCenter {
    text-align: center;
}

.alignLeft {
    text-align: left;
}

.fontBold {
    font-weight: bold;
}

.font12px {
    font-size: 12px;
}

.font18px {
    font-size: 18px;
}


.padding5px {
    padding: 5px;
}

.pointer {
    cursor: pointer;
}

.paddingL63px {
    padding-left: 63px;
}

.listDisc {
    list-style-type: disc;
}

.prices {
    i {
        font-size: 60px;
        margin-bottom: 16px;
        &:last-child {
            font-size: 18px;
        }
    }
    p {
        margin-bottom: 16px;
    }
}

.footerPrices {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.marginTop50px {
    margin-top: 50px;
}

.marginBottom50px {
    margin-bottom: 50px;
}



.details{
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 5.5px 0 5.5px;
        border-color: #000000 transparent transparent transparent;
        position: relative;
        top: 17px;
        left: 5px;
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -moz-transition: all .4s ease-out;
        -o-transition: all .4s ease-out;
        -webkit-transition: all .4s ease-out;
        transition: all .4s ease-out;
        
    }
}

.detailsUl {
    li {
        &:last-child {
            margin-bottom: 50px
        }
    }
}

#map {
    width: 100%;
    height: 100%;
    min-height: 400px;
}

.carousel {
    img {
        max-width: 1200px;
        max-height: 660px;
    }
}

.fa-bars {
    font-size: 32px;
    color: #fff;
}


.owl-carousel {
    width: 1040px;
    height: 660px;
    .slide {
        width: 1040px;
        height: 660px;
    }
}

.fontSerif {
    font-family: 'Georgia', serif;
}

.indexRelative {
    position: relative;
    top: -116px;
}

.borderTop {
    border-top: 3px solid #000;
}

.positionRelative {
    position: relative;
}

.w100h100 {
    width: 100%;
    height: 100%;
}

.font18px {
    font-size: 18px;
    font-weight: 600;
}
// hero 

.heroImgIndex,
.heroImgRoom,
.heroImgAbout,
.heroImgBarn,
.heroImgNeighbourhood,
.heroImgLocation,
.heroImgPrices,
.heroImgKids,
.heroImgFood,
.heroImgMedia {
    width: 100%;
    height: 740px;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    .cleanContainer{
        max-width: 1400px;
        margin: 0 auto;
        height: 60px;
        position: relative;
        .menu {
            float: left;
            position: absolute;
            top: 35px;
            left: 156px;
            z-index: 555;
        }
        .menuFixed {
            float: left;
            position: fixed;
            padding: 20px 20px 13px 20px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.5);
            top: 17px;
            left: 136px;
            z-index: 555;
        }
        .logotype {
            width: 250px;
            height: 250px;
            background: url("../images/logo.png") no-repeat center;
            background-size: contain;
            float: left;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            z-index: 5;
        }
        .reservationButton2 {
            display: inline-block;
            color: #000;
            background: #fff;
            border: none;
            border-radius: 5px;
            width: 110px;
            height: 35px;
            float: right;
            text-transform: uppercase;
            font-family: "Open Sans", sans-serif;
            outline: none;
            cursor: pointer;
            position: relative;
            top: 37px;
            font-size: 13px;
            margin-right: 131px;
            z-index: 5;
            -webkit-box-shadow: 0px 0px 6px 5px rgba(50, 50, 50, 0.28);
            -moz-box-shadow: 00px 0px 6px 5px rgba(50, 50, 50, 0.28);
            box-shadow: 0px 0px 6px 5px rgba(50, 50, 50, 0.28);
        } 
    }
}

.kids {
    section {
        img {
            width: 100%;
            margin-top: 50px;
        }
        &:nth-child(2) {
            padding-left: 30px;
        }
    }
}


.displayInBl {
    display: inline-block;
}

.reservationText {
    background: #fff;
    position: relative;
    top: -100px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
}

.heroText {
    background: #fff;
    position: relative;
    top: -100px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    letter-spacing: 1px;
    h2 {
        font-family: 'Open Sans', sans-serif;
        margin-bottom: 30px;
    }
    p {
        line-height: 1.4;
    }
}

footer {
    .row {
        [class*="col-"] {
            padding: 0;
        }
        p {
            &:first-child {
                margin-top: 20px;
            }
        }
    }
    img {
        width: 32px;
    }
}

@media (min-width: 1400px) {
    .reservationText {
        max-width: 1200px;
    }
    
    .heroText {
        max-width: 1200px;
    }
}

@media (min-width: 1200px) {
    .reservationText {
        width: 75%;
        padding: 19px 0px 40px 0px;
    }
    
    .heroText {
        width: 75%;
        padding: 40px 60px 40px 60px;
        p {
        font-size: 28px;
        }
        h2 {
        font-size: 50px;
        }
    }
}

@media (min-width: 991px ) and (max-width: 1199px) {
    .reservationText {
        width: 75%;
    }
    
    .heroText {
        width: 75%;
        p {
            font-size: 22px
        }
        h2 {
            font-size: 36px;
        }
    }    
}

@media (min-width: 540px ) and (max-width: 990px) {
    .reservationText {
        width: 75%;
    }
    
    .heroText {
        width: 75%;
        p {
            font-size: 18px;
        }
        h2 {
            font-size: 30px;
        }
    } 
    
    .heroImgIndex,
    .heroImgRoom,
    .heroImgAbout,
    .heroImgBarn,
    .heroImgNeighbourhood,
    .heroImgLocation,
    .heroImgPrices,
    .heroImgKids,
    .heroImgFood,
    .heroImgMedia {
        background-attachment: initial;
        .cleanContainer {
            .menu {
                margin-left: 50px;
                left: 0;
            }
            .menuFixed {
                left: 0;
                margin-left: 30px;
            }
            .reservationButton2 {
                margin-right: 50px;
            }
        }
        
    } 
    
    
    .reservationWrapper {
        height: 210px;
        .reservation {
            .closeReservationWrapper {
                float: none;
                img {
                    display: block;
                    margin: 0 auto;
                    position: absolute;
                    top: 0;
                    right: 10px;
                }
            }
            
            > img {
                display: none;
            }
            button {
                display: block;
                margin: 0 auto;
                position: relative;
                top: 30px;
                width: 290px;
            }
            .displayInBl {
                display: block;
                input {
                    position: relative;
                    left: 50%;
                    transform: translateX(-104%);
                    padding-right: 0px;
                }
                ul {
                    position: relative;
                    left: 50%;
                    transform: translateX(-49.4%);
                    top: 15px;
                    li {
                        top: 0px
                    }
                }
                #datepicker {
                    margin-left: 0px;
                }
                
            }
        }
    }
    
    footer {
        .row {
            display: flex;
            flex-direction: column;
            .mediaFooter {
                order: -1;
            }
        }
    }
    
}



@media (max-width: 540px) {
    .container {
        margin: 0 auto;
        .mini-container {
            margin-top: 15px;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    
    .heroText {
        position: static;
        p {
            font-size: 16px;
        }
        h2 {
            font-size: 26px;
        }
    }
    .indexRelative,
    .foodRelative {
        position: static;
    }
    .heroImgIndex,
    .heroImgRoom,
    .heroImgAbout,
    .heroImgBarn,
    .heroImgNeighbourhood,
    .heroImgLocation,
    .heroImgPrices,
    .heroImgKids,
    .heroImgFood,
    .heroImgMedia {
        height: 100vh;
        background-attachment: initial;
        .cleanContainer {
            .menu {
                margin-left: 50px;
                left: 0;
            }
            .menuFixed {
                left: 0;
                margin-left: 30px;
            }
    
            .reservationButton2 {
                top: 50px;
                position: relative;
                margin: 0 auto;
                display: block;
                float: none;
                
            }
            .logotype {
                float: none;
                left: 50%;
                width: 150px;
                height: 150px;
            }
        }
    }
    
    footer {
        .row {
            display: flex;
            flex-direction: column;
            .mediaFooter {
                order: -1;
            }
        }
    }
    
    .reservationWrapper {
        height: 210px;
        .reservation {
            .closeReservationWrapper {
                float: none;
                display: block;
                margin: 0 auto;
                img {
                    display: block;
                    margin: 0 auto;
                    position: absolute;
                    top: 154px;
                    padding: 0;
                }
            }
            
            > img {
                display: none;
            }
            button {
                display: block;
                margin: 0 auto;
                position: relative;
                top: 30px;
                width: 290px
            }
            .displayInBl {
                display: block;
                input {
                    position: relative;
                    left: 50%;
                    transform: translateX(-104%);
                    padding-right: 0px;
                }
                ul {
                    position: relative;
                    left: 50%;
                    transform: translateX(-49.4%);
                    top: 10px;
                    li {
                        top: 0px;
                        text-align: left;
                    }
                }
                #datepicker {
                    margin-left: 0px;
                }
                
            }
        }
    }
}

.container1000 {
    max-width: 1040px;
    margin: 51px auto 0 auto;
    .h2room {
        margin-bottom: 0;
        font-size: 31px;
        font-weight: 600;
        font-family: 'Open Sans',sans-serif;
        position: relative;
        top: 0px;
        left: 5px;
    }
    .reservationGallery {
        height: 250px;
        margin-top: 27px;
        .row {
            height: 100%;
            section {
                height: 100%;
                img {
                    height: 100%;
                    width:100%;
                }
            }
            div {
                h2 {
                    font-size: 31px;
                    font-weight: 600;
                    font-family: 'Open Sans',sans-serif;
                    position: relative;
                    top: 12px;
                    left: 5px;
                }
                .pricePosition {
                    position: relative;
                    top: 30px;
                    left: -142px;
                    font-size: 30px;
                    font-weight: bold;
                    font-family: 'Open Sans', sans-serif;
                    span {
                        font-size: 20px;
                    }
                }
                .buttonPosition {
                    position: relative;
                    top: -51px;
                    left: 176px;
                }
                .roomPosition {
                    position: relative;
                    top: 0px;
                    left: 7px;
                    font-size: 18px;
                }
                .buttonReservation {
                    width: 150px;
                    height: 40px;
                    color: #fff;
                    background: #567ad3;
                    outline: none;
                    border: none;
                    cursor: pointer;
                    font-size: 14px;
                    text-transform: uppercase;
                    &:hover{
                        background: #789cf5;
                    }
                }
            }
        }
    }
}


.mini-container {
    img,
    .single-item,
    .slick-slide {
        outline: none;
        user-select: none;
        &:focus {
            outline: none;
            user-select: none;
        }
        &:active {
            outline: none;
            user-select: none;
        }
        &:hover {
            outline: none;
            user-select: none;
        }
    }
}


* { -webkit-tap-highlight-color: rgba(0,0,0,0); }


@media (max-width: 991px) {
    .reservationGallery {
        height: 180px;
    }
}

::-webkit-input-placeholder { 
    color: #000;
    opacity: 1;
}
::-moz-placeholder { 
    color: #000;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #000;
    opacity: 1;
}
:-moz-placeholder {
    color: #000;
    opacity: 1;
}

.gray {
    background: #eaeaea;
    height: 534px;
    h2 {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
    }
    .youtube {
        max-width: 700px;
        width: 100%;
        height: 444px;
        padding: 0px 0 50px 0;
        display: block;
        margin: 0 auto;    
    }
}

.gray2 {
    background: #eaeaea;
    h2 {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
    }
    .mapNeighbourhood {
        max-width: 760px;
        width: 100%;
        height: 500px;
        display: block;
        margin: 0 auto 50px auto;
    }
}

.mediaFilm {
    float: left;
    display: block;
    width:100%;
    margin-top: 50px;
}

.shadow {
    height: 232px;
    width: 100%;
    background-position: top;
}

video { 
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
}

.borderT {
    border-top: 1px solid #000;
}

.borderB {
    border-bottom: 1px solid #000;
}

.heroImgPrices {background-position: top;}
.heroImgAbout {background-position: bottom;}
.heroImgNeighbourhood {background-position: bottom;}
.heroImgRoom {background-position: bottom;}
.neighbourhood6 {background-position: bottom;}
.neighbourhood2 {background-position: bottom;}
.neighbourhood10 {background-position: center;}

@import "mediaQueries";


#myLink {
    display: none;
}

.reservationButton {display: none !important}