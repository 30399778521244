.boxShadow(@offseth: 1px, @offsetv: 1px, @radius: 5px, @color: rgba(0, 0, 0, 0.25)) {
	box-shadow: @offseth @offsetv @radius @color;
	background-clip: padding-box;
}
.boxShadowInner(@offseth: 1px, @offsetv: 1px, @radius: 5px, @color: rgba(0, 0, 0, 0.25)) {
	box-shadow: inset @offseth @offsetv @radius @color;
}
.boxShadowOff() { box-shadow: none; }

.backgroundGradient (@startColor: #eee, @endColor: white) {
	background-color: mix(@startColor, @endColor);
	background-image: linear-gradient(to bottom, @startColor 0%, @endColor 100%);
}

.opacity(@opacity) {
	@opacityPercentage: @opacity * 100;
		   opacity: @opacity;
		-ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=(@{opacityPercentage}))"; 
			filter: ~"alpha(opacity = (@{opacityPercentage}))";
}

.transition(@property:all, @duration:0.4s, @ease:ease-out) { transition: @property @duration @ease; }