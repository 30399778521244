@gap: 20px;

.row {
	clear: both;
	.clearFix();
	text-align: center;
	
	&.centered { display: inline-block; }
	
	&.three .column { width: (@pageWidth - @gap * 2) / 3; }
	&.four .column { width: (@pageWidth - @gap * 3) / 4; }

	.column {
		box-sizing: border-box;
		float: left;
		width: (100% - 5%) / 2;
		margin-bottom: 40px;
		margin-right: 5%;
		
		&.last { margin-right: 0 !important; }
		
		&.middle {
			float: none;
			margin-left: auto;
			margin-right: auto;
		}
		
		p:last-of-type { margin-bottom: 0; }
	}
}

.contentColumns {
	column-gap: 60px;
	column-count: 2;
	margin-bottom: 1.5em;
	
	&.three { column-count: 3; }
	&.four { column-count: 4; }

	p:last-of-type { margin-bottom: 0; }
}

@media only screen and (max-width: 1020px) {
	.row {
		.column {
			vertical-align: top;
			float: none;
			display: inline-block;
			margin-right: 0;
			width: auto;

			&.last { margin-right: 0 !important; }
		}
	}
}

@media only screen and (max-width: 450px) {
	.row {
		.column {
			margin-left: 0 !important;
			margin-right: 0 !important;
			width: 100% !important;
			
			&.last { margin-right: 0 !important; }
		}
	}
	
	h2 { font-size: 32/14 + 0em; }
	h3 { font-size: 21/14 + 0em; }
	h4 { font-size: 18/14 + 0em; }	
}

@media only screen and (max-width: 770px) {
	.contentColumns { column-count: 1; }
}

@media only screen and (max-width: 500px) {
	.contentColumns img { width: 100%; height: auto; }
}

.row:before, .row:after {
  content: "";
  display: table;
  clear: both; }

[class*="col-"] {
  float: left;
  padding: 5px;
  min-height: 1px;}

@media (min-width: 992px) {
    .l-col-1 {width: 8.33%;}
    .l-col-2 {width: 16.66%;}
    .l-col-3 {width: 25%;}
    .l-col-4 {width: 33.33%;}
    .l-col-5 {width: 41.64%;}
    .l-col-6 {width: 50%;}
    .l-col-7 {width: 58.35%;}
    .l-col-8 {width: 66.66%;}
    .l-col-9 {width: 75%;}
    .l-col-10 {width: 83.34%;}
    .l-col-11 {width: 91.67%;}
    .l-col-12 {width: 100%;}
    .l-col-325 {width: 32.2%;}
}

@media (min-width: 540px) and (max-width: 991px) {
    .m-col-1 {width: 25%;}
    .m-col-2 {width: 50%;}
    .m-col-3 {width: 75%;}
    .m-col-4 {width: 100%;}
    .m-col-33 {width: 33.33%;}
    .m-col-48 {width: 48%;}
}

@media (max-width: 539px) {
    .s-col-1 {width: 25%;}
    .s-col-2 {width: 50%;}
    .s-col-3 {width: 75%;}
    .s-col-4 {width: 100%;}
}