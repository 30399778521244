#cookieWarning {
	background-color: @black;
	background-color: rgba(0,0,0, 0.77);
	.font11();
	padding: 4px 0 0;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	color: @white;
	z-index: 100000;

	div {
		width: @pageWidth;
		margin: 0 auto;
		position: relative;
		padding: 10px 20px 10px 10px;
	}

	p { margin: 0; }

	a.close {
		position: absolute;
		display: block;
		top: 4px;
		right: 4px;
		color: @white;

		font-size: 3em;
		font-family: @arial;
		font-weight: normal;
		line-height: .5;
		text-decoration: none;

		&:hover { font-weight: bold; }
	}
}

@media only screen and (max-width: 960px) {
	#cookieWarning div { width: auto; }
}