@arial: Arial, Helvetica, sans-serif;
@arialNarrow: "Arial Narrow", @arial;
@futura: "Futura LT", Futura, "Trebuchet MS", @arial;
@helvetica: "Helvetica Neue", @arial;
@helveticaLight: "HelveticaNeue-Light", "Helvetica Neue Light", @helvetica; 
@geneva: Geneva, Tahoma, Verdana, sans-serif;
@georgia: Georgia, Palatino, "Palatino Linotype", "Times New Roman", serif;
@palatino: Palatino, "Palatino Linotype", Georgia, Times, "Times New Roman", serif;
@tahoma: Tahoma, Geneva, Verdana, sans-serif;
@times: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
@verdana: Verdana, Geneva, Tahoma, sans-serif;

.textLeft { text-align: left; }
.textRight { text-align: right; }
.textCenter { text-align: center; }
.textJustify { text-align: justify; }

.textBold, .bold { font-weight: bold; }
.textNormal, .normal { font-weight: normal; }
.textLight, .light { font-weight: lighter; }

.textHide, .hideText { text-indent: 101%; overflow: hidden; white-space: nowrap; }

.textCaps, .textCapitalize { text-transform: uppercase; }

.textPreventBreakouts {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;

	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.valignMiddle { vertical-align: middle; }
.valignTop { vertical-align: top; }
.valignBottom { vertical-align: bottom; }

.font8 { font-size: (8/@baseFontSize) + 0em; }
.font9 { font-size: (9/@baseFontSize) + 0em; }
.font10 { font-size: (10/@baseFontSize) + 0em; }
.font11 { font-size: (11/@baseFontSize) + 0em; }
.font12 { font-size: (12/@baseFontSize) + 0em; }
.font13 { font-size: (13/@baseFontSize) + 0em; }
.font14 { font-size: (14/@baseFontSize) + 0em; }
.font15 { font-size: (15/@baseFontSize) + 0em; }
.font16 { font-size: (16/@baseFontSize) + 0em; }
.font18 { font-size: (18/@baseFontSize) + 0em; }
.font21 { font-size: (21/@baseFontSize) + 0em; }
.font24 { font-size: (24/@baseFontSize) + 0em; }
.font28 { font-size: (28/@baseFontSize) + 0em; }
.font32 { font-size: (32/@baseFontSize) + 0em; }
.font40 { font-size: (40/@baseFontSize) + 0em; }
.font48 { font-size: (48/@baseFontSize) + 0em; }
.font64 { font-size: (64/@baseFontSize) + 0em; }

.lineHeight1 { line-height: 1; }
.lineHeight11 { line-height: 1.1; }
.lineHeight12 { line-height: 1.2; }
.lineHeight13 { line-height: 1.3; }
.lineHeight14 { line-height: 1.4; }
.lineHeight15 { line-height: 1.5; }
.lineHeight16 { line-height: 1.6; }
